import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { FormProvider, RHFUpload } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  invalidateServicesStatsQueries,
  useGetServiceForm,
  useSubmitDocument,
} from '#/api/servicesQueries';
import DocumentHistory from '#/components/shared/DocumentHistory';

type Props = {
  serviceName: string;
  serviceInvalidateKey: string;
  documentType: string;
  documentUpdated?: () => void;
};

export default function UploadSignedDoc({
  serviceName,
  serviceInvalidateKey,
  documentType,
  documentUpdated,
}: Props) {
  const { translate } = useLocales();
  const { mutateAsync: uploadSigned, isLoading } =
    useSubmitDocument(serviceName);

  // const { mutateAsync: deleteSignedDocument, isLoading: isDeleting } =
  //   useDeleteDocument(serviceName);

  const { data: service } = useGetServiceForm(serviceInvalidateKey);

  const defaultValues = useMemo(
    () => ({
      signed_document: service?.document_history[0]?.signed_document ?? '',
    }),
    [service?.document_history]
  );

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append('signed_document', data.signed_document);

    await uploadSigned(formData)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.document_submit'))
        );
        invalidateServicesStatsQueries.getServiceForm(serviceInvalidateKey);
        setValue('signed_document', '', { shouldValidate: true });
        if (documentUpdated) {
          documentUpdated();
        }
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.document_submit'))
        );
      });
  };

  // const onDelete = async () => {
  //   await deleteSignedDocument('last-will').then(() => {
  //     reset({
  //       signed_document: '',
  //     });
  //     invalidateServicesStatsQueries.getServiceForm(serviceInvalidateKey);
  //   });
  // };

  useEffect(() => {
    if (service?.document_history) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [service?.document_history]);

  const handleDocDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        setValue('signed_document', newFile, { shouldValidate: true });
        handleSubmit(onSubmit)();
      }
    },
    // eslint-disable-next-line
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {service?.document_history?.length === 0 && (
        <RHFUpload
          disabled={isLoading}
          name="signed_document"
          placeholderHeading={String(
            translate('global.signedDoc.placeholder.headingText')
          )}
          onDrop={handleDocDrop}
          accept={{
            'pdf/*': ['.pdf'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc', '.docx'],
          }}
        />
      )}
      {service?.document_history?.length > 0 && (
        <DocumentHistory
          document_history={service?.document_history}
          documentType={documentType}
          onDrop={handleDocDrop}
          isUploading={isLoading}
        />
      )}
    </FormProvider>
  );
}
