import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Times-Roman',
    fontSize: 11,
    paddingVertical: 30,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  headSection: {
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginLeft: 40,
    marginRight: 40,
  },
  section2: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40,
    marginRight: 40,
  },
  pointParagraphs: {
    display: 'flex',
    flexDirection: 'row',
  },
  pointContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 3,
    gap: 9,
  },
  underlinedText: {
    textDecoration: 'underline',
    marginVertical: 3,
  },
});

export default styles;
