import { Box, Button, Collapse, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Iconify from '#/components/shared/ui/Iconify';
import { fDate } from '#/utils/formatTime';
import useLocales from '#/hooks/useLocales';
import DocumentHistoryItem from '#/components/shared/DocumentHistoryItem';
import { RHFUploadButton } from '#/components/shared/hook-form';

type Props = {
  document_history: [
    {
      signed_document: string;
      uploaded_at: string;
      documentType: string;
    },
  ];
  documentType: string;
  onDrop?: (files: File[]) => void;
  isUploading?: boolean;
};

export default function DocumentHistory({
  document_history,
  documentType,
  onDrop,
  isUploading,
}: Props) {
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const handleShowHistory = () => setShowHistory((prev) => !prev);

  const { translate } = useLocales();

  // eslint-disable-next-line no-unsafe-optional-chaining
  const lastIndex = document_history?.length - 1;

  return (
    <Box
      sx={{
        backgroundColor: '#E9F7EF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: 2,
        paddingY: 3,
        rowGap: 1,
      }}
    >
      <Link
        href={document_history[lastIndex]?.signed_document}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="text"
          endIcon={<Iconify icon="grommet-icons:share" color="#219653" />}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: '#219653',
              textDecoration: 'underline',
            }}
          >
            {String(
              translate('global.signedDoc.placeholder.currentlySigned', {
                grammarFix: translate(
                  `global.signedDoc.placeholder.grammarFix.${documentType}`
                ),
                docType: translate(
                  `global.signedDoc.placeholder.doctype.${documentType}`
                ),
              })
            )}
          </Typography>
        </Button>
      </Link>

      <Button onClick={handleShowHistory}>
        <Iconify
          icon="material-symbols:history-toggle-off"
          color={showHistory ? 'primary.main' : 'text.primary'}
          width={24}
          height={24}
        />
      </Button>

      <Collapse in={showHistory}>
        {document_history?.map((doc, index) => (
          <DocumentHistoryItem
            key={index}
            signed_document={doc?.signed_document}
            uploaded_at={doc?.uploaded_at}
          />
        ))}
      </Collapse>

      <Typography variant="subtitle1" fontWeight="light" color="text.secondary">
        {String(
          translate('global.signedDoc.updatedAt', {
            date: fDate(document_history[0]?.uploaded_at, 'dd. MMM yyyy', 'de'),
          })
        )}
      </Typography>
      <Stack direction="row" justifyContent="flex-end">
        <RHFUploadButton
          name="signed_document"
          accept={{
            'pdf/*': ['.pdf'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc', '.docx'],
          }}
          onDrop={onDrop}
          isUploading={isUploading}
        />
      </Stack>
      {/* <LoadingButton */}
      {/*  variant="text" */}
      {/*  disabled={isUploading} */}
      {/*  onClick={onDocUpload} */}
      {/*  loading={isUploading} */}
      {/* > */}
      {/*  <Typography variant="subtitle1" fontWeight="normal"> */}
      {/*    {String(translate('global.uploadNew'))} */}
      {/*  </Typography> */}
      {/* </LoadingButton> */}
    </Box>
  );
}
